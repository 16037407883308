import { ElementType, ReactElement, ReactNode } from 'react';
import { ClassValue } from 'clsx';
import {
  TButtonRound,
  TButtonSize,
  TOnBoardingSteps,
  TVariant,
  TYesOrNo,
  TDoctorStatus,
  TDays,
  TConsultationStatus,
  TConsultation,
  TPatientStatus,
  TPatientConsultationStatus,
  TBookingSteps,
  TGenders,
  TFileTypes,
  TVisibilityTypes,
  TEscriptsStatus,
  TPatientHealthStatus,
  TEscriptTokenStatus,
  TLimits,
  TFollowUpPeriod,
  TErx
} from './types';
import { To } from 'react-router-dom';

export interface IConstantConditions {
  id: number;
  className: ClassValue;
  value: string;
  icon: ElementType;
}

export interface IOnboardingSteps {
  step: TOnBoardingSteps;
  setStep: (x: TOnBoardingSteps) => void;
}

export interface IYesOrNo {
  value: TYesOrNo;
  label: string;
}

export interface IGuard {
  children: ReactElement;
}

export interface IProfile {
  profile_pic: any;
  first_name: string;
  lastName: string;
  registration: string;
  email: string;
  timeZone: string;
  contact_no: string;
  street: string;
  city: string;
  state: string;
  postCode: string;
  verified: boolean;
}
export interface ISelectOptions {
  value: any;
  label: string;
  others?: any;
  disabled?: boolean;
  icon?: ReactNode;
}

export interface IStatusFilter {
  value: string;
  text: string;
  className: string;
}

export interface IBaseActionType {
  title?: ReactNode;
  variant?: TVariant;
  className?: string;
  icon?: ReactNode;
  iconSide?: 'left' | 'right';
  disabled?: boolean;
  size?: TButtonSize;
  round?: TButtonRound;
}

export interface IMySummary {
  month_appointment: number;
  new_message: number;
  total_consultation: number;
  total_patient: number;
  week_appointment: number;
  active_patient: number;
  inactive_patient: number;
}

export interface NotificationArray {
  id: string;
  content: string;
  created_at: string;
  status: string;
  title: string;
}
export interface INotificationType {
  [key: string]: NotificationArray;
}
export interface ITimeOptionType {
  value: string;
  label: string;
  disabled?: boolean;
}
export interface IPatient {
  first_name: string;
  id: string;
  last_name: string;
}
export interface IProduct {
  className: string;
  id: string;
  price: number;
  title: string;
}
export interface IScheduleProps {
  start_at: string;
  end_at: string;
  calendar_day: string;
}
export interface IConsulationsProps {
  id: string;
  doctor_set_date: string;
  duration: number;
  enquiry: string | null;
  status: string;
  patient: IPatient;
  product: IProduct;
  product_id: number;
  schedule: IScheduleProps;
}
export interface DoctorMonthlyAppointmentProps {
  consultation: Array<IConsulationsProps>;
  date: any;
  schedules: Array<IScheduleProps>;
  unavailabilities: any;
}

export interface IProfileEdit {
  profile_pic: any;
  first_name: string;
  last_name: string;
  provider_no: string;
  email: string;
  timezone: string;
  contact_no: string;
  city: string;
  state: string;
  street: string;
  postcode: string;
  description: string;
  phone_verification: boolean;
}

export interface IDoctorProfile {
  doctor_id: string;
  first_name: string;
  last_name: string;
  date_of_birth: Date;
  street: string;
  city: string;
  state: string;
  postcode: string;
  country: string;
  provider_no: string;
  prescriber_no: string;
  registration_no: string;
  profile_pic: string;
  description: string;
  onboarding_step: TOnBoardingSteps;
  hpi_no: string | null;
  pbs_prescriber_no: string | null;
  specialist_qualifications: string | null;
}

export interface IButtonAction extends IBaseActionType {
  onClick: (e: any) => void;
  type?: 'button' | 'submit';
  isLoading?: boolean;
}

export interface IMenu {
  // to: To;
  label: string;
  icon?: ReactNode;
  itemRound?: TButtonRound;
  className?: ClassValue;
  divider?: boolean;
  setting?: boolean;
  disabled?: boolean;
}

export interface IMenuLink extends IMenu {
  to: To;
  state?: { [x: string]: any };
  replace?: boolean;
}

export const isIMenuLink = (action: any): action is IMenuLink => typeof action.to === 'string';

export interface IMenuAtag extends IMenu {
  href: string;
  target?: string;
}

export const isIMenuAtag = (action: any): action is IMenuAtag => typeof action.href === 'string';

export interface IMenuButton extends IMenu {
  onClick: (event: any) => void;
  type?: 'button' | 'submit';
}

export const isIMenuButton = (action: any): action is IMenuButton =>
  typeof action.onClick === 'function';

export interface IMenuActions extends IMenu {
  actions: IMenuTypes[];
  // offset?: number | [number, number];
  hoverable?: boolean;
}

export const isMenuActions = (action: any): action is IMenuActions =>
  action.actions instanceof Array;

export type IMenuTypes = IMenuLink | IMenuButton | IMenuActions;

export interface IConsultationDetails {
  id: string;
  reference_id: number;
  doctor: IDoctorDetails;
  patient: IPatientDetails;
  start_at: Date;
  end_at: Date;
  consultation_type: IConsultationType;
  enquiry: string;
  step: TBookingSteps;
  status: TConsultationStatus;
  prescription: IPrescriptionDetails;
  tga_approval: boolean;
  tga_acceptance: boolean;
  script_approval: boolean;
  script_disabled: boolean;
  documents: IConsultationDocuments[];
  can_finish: boolean;
  notes: string | null;
}

export interface IConsultationHistory {
  consultation_status: TConsultationStatus;
  consultation_ref_id: number;
  consultation_date: Date;
  consultation_type: IConsultationType;
  script: IConsultationDocuments;
  prescription: IPrescriptionDetails;
}

export interface IPatientConsultationHistory {
  id: string;
  reference_id: number;
  doctor: IDoctorDetails;
  start_at: Date;
  end_at: Date;
  consultation_type: IConsultationType;
  enquiry: string;
  status: TConsultationStatus;
  tga_approval: boolean;
  tga_acceptance: boolean;
  script_approval: boolean;
  script_disabled: boolean;
  can_finish: boolean;
  notes: string | null;
}

export interface IConsultationDetailsNoPrescription {
  id: string;
  doctor: IDoctorDetails;
  patient: IPatientDetails;
  start_at: Date;
  end_at: Date;
  consultation_type: IConsultationType;
  enquiry: string;
  step: TBookingSteps;
  status: TConsultationStatus;
  tga_approval: boolean;
  tga_acceptance: boolean;
  script_approval: boolean;
  script_disabled: boolean;
  documents: IConsultationDocuments[];
}

export interface IConsultationDocuments {
  filename: string;
  file_url: string;
  file_key: string;
  file_type: TFileTypes;
  uploaded_by: string;
  upload_date: Date;
  visibility: TVisibilityTypes;
}

export interface IExtendedDocuments extends IConsultationDocuments {
  consultation: IConsultationDetails;
}

export interface ITokenResponse {
  code: string;
  success: boolean;
  message: string;
  token: string;
  refresh_token: string;
  token_expires: string;
  refresh_token_expires: string;
  otpHash: string;
  verification_code: string;
  doctor: IUser;
}
export interface IUserToken {
  id: string;
  status: TDoctorStatus;
}

export interface IUser {
  id: string;
  email: string;
  timezone: string;
  contact_no: string;
  profile: IDoctorProfile;
  phone_verification: boolean;
  email_verification: boolean;
  status: TDoctorStatus;
}
export interface IMeResponse {
  code: string;
  success: boolean;
  message: string;
  is_from_admin: boolean;
  doctor: IUser;
}

export interface IOtpSendResponse {
  code: string;
  success: boolean;
  message: string;
  fullHash: string;
  verification_code: number;
}

export interface IOtpVerifyResponse {
  code: string;
  success: boolean;
  message: string;
}
export interface ITimeRange {
  start_at: string;
  end_at: string;
}
export interface ISchedule {
  calendar_day: TDays;
  schedules: ITimeRange;
}
export interface IDoctorScheduleResponse {
  code: string;
  success: boolean;
  message: string;
  schedules: ISchedule;
}
export interface IFileCheckResponse {
  success: boolean;
  message: string;
}
export interface IPatientProductIPatientProduct {
  className: string;
  id: string;
  title: string;
}
export interface IPatientSchedule {
  end_at: string;
  start_at: string;
}
export interface IUpcomingConsultation {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  enquiry: string;
  mobile_no: string;
  patient_id: string;
  profile_pic: string | null;
  start_at: string;
  end_at: string;
}

export interface ITableHeaderProps {
  name: string;
  field: string;
  sortable: boolean;
  customSort: boolean;
  filter: boolean;
  filterVal: Array<IStatusFilter> | null;
  width: string;
}

export interface IPatientProduct {
  title: string;
  className: string;
}
export interface IConsulation {
  type: string | null;
  doctor_set_date: string;
  product: IPatientProduct;
}

export interface IPatientProfile {
  patient_id: string;
  first_name: string;
  middle_name: string;
  last_name: string;
  pronoun: string;
  gender: TGenders;
  date_of_birth: Date;
  street: string;
  city: string;
  state: string;
  postcode: string;
  country: string;
  medicare_no: string;
  medicare_valid_to: string;
  profile_pic: string;
  is_australian_resident: 1 | 2;
  is_over_18: 1 | 2;
  tried_conventional_treatment: 1 | 2;
  medical_symptoms: string;
  medical_conditions: string;
  conditions: string;
  other_conditions: string;
  how_long_suffered: string;
  using_any_medicine: string;
  have_any_allergy: string;
  allergy_details: string;
  relevant_notes: string;
  about_polln: string;
  currently_being_treated: 1 | 2;
  current_prescription: 1 | 2;
  taking_any_medication: 1 | 2;
  taking_any_medication_list: string;
  achieve_polln: string;
  addtl_info: string;
  patient_notes: string;
  careteam_notes: string;
  careteam_notes_last_edit_at: Date;
  careteam_notes_last_edit_by: string;
}

export interface IPatientHistory {
  patient_id: string;
  english_language: 1 | 2;
  primary_language: string;
  video_comfortable: 1 | 2;
  heart_lung_condition: string;
  mental_neuro_condition: string;
  muscle_condition: string;
  other_condition: string;
  other_condition_list: string;
  currently_taking_medications: 1 | 2;
  current_medications: string;
  medicine_with_prescription: 1 | 2;
  medicine_with_prescription_details: string;
  medicine_without_prescription: 1 | 2;
  medicine_without_prescription_details: string;
  smoke_tobacco: 1 | 2;
  smoke_tobacco_frequency: string;
  drink_alcohol: 1 | 2;
  drink_alcohol_frequency: string;
  pregnant_breastfeeding: 1 | 2;
  psychiatric_disorder: 1 | 2;
  family_with_health_condition: 1 | 2;
  family_with_health_condition_details: string;
  injury_surgery__hospitalization: 1 | 2;
  injury_surgery__hospitalization_details: string;
  current_diet: string;
  physical_activity_level: string;
  stress_emotions: string;
  status: TPatientHealthStatus;
}

export interface IPatientBackground {
  patient_id: string;
  cannabis_used: string;
  have_medical_prescription: string;
  cannabis_history: string;
  has_allergy: string;
  allergy: string;
  allergy_severity: string;
  has_surgery: string;
  surgery: string;
  surgery_date: Date;
  alcohol_used: string;
  tobacco_used: string;
  exescise_activity: string;
  family_history: string;
  other_family_history: string;
  medical_history: string;
  other_medical_history: string;
  currently_being_treated: string;
  current_clinic: string;
  current_doctor: string;
  current_date: Date;
  currently_taking_medication: string;
  current_medication: string;
  current_medication_dose: string;
  current_work_status: string;
  current_work: string;
}
export interface IPatientData {
  id: string | number;
  email: string;
  timezone: string;
  contact_no: string;
  profile: IPatientProfile | null;
  phone_verification: boolean;
  email_verification: Boolean;
  status: TDoctorStatus;
  consultation_status: TConsultationStatus;
  last_consultation_date: string;
}

export interface ITableProps {
  contactNo: string;
  email: string;
  firstName: string;
  id: string;
  lastName: string;
  patientId: number;
  profilePic: string;
  status: string;
  time: string;
}
export interface ICalendarConsulation {
  date: string; // or Date?
  enquiry: string;
  id: string;
  setDate: string; // or Date?
  timeEnd: string; // or Date?
  timeStart: string; // or Date?
  title: string;
  type: TConsultation;
}

export interface IHistoryProduct {
  title: string;
  className: string;
}
export interface IFileUploads {
  file_url: string;
  filename: string;
}
export interface IAppointmentHistory {
  id: string;
  enquiry: string | null;
  doctor_set_date: number | Date | any;
  product: IHistoryProduct | null;
  script_uploads: Array<IFileUploads> | null;
  tga_status: string;
  tga_uploads: Array<IFileUploads> | null;
}

export interface IAddress {
  street: string;
  city: string;
  state: string;
  postcode: string;
}

export interface IPatientResponse {
  code: string;
  message: string;
  patient: IPatientData | null;
}

export interface ITimes {
  id?: string;
  daysOfWeek?: string;
  timeStart: string;
  timeEnd: string;
}

export interface IDaysOfWeek {
  [day: string]: ITimes[];
}

export interface IInputSchedules {
  id?: string;
  start_at: Date;
  end_at: Date;
}

export interface IScheduleDetails {
  id: string;
  recurring_id: string | null;
  date: Date[];
  start_at: Date;
  end_at: Date;
  status: TConsultationStatus | null;
}

export interface IConsultationsResponse {
  code: string;
  message: string;
  success: boolean;
  consultations: IUpcomingConsultation[];
}

export interface IConsultationType {
  id: string;
  name: string;
  duration: number;
  is_default: boolean;
  price: string;
  capitalize_name: TConsultation;
  has_notes: boolean;
  has_prescription: boolean;
  has_video_call: boolean;
}

export interface IDoctorDetails {
  id: string;
  email: string;
  timezone: string;
  contact_no: string;
  profile: IDoctorProfile;
}

export interface IPatientDetails {
  id: string;
  email: string;
  timezone: string;
  contact_no: string;
  profile: IPatientProfile;
  health_history: IPatientHistory;
  background: IPatientBackground;
  billing_address: string;
  delivery_address: string;
  phone_verification: boolean;
  email_verification: boolean;
  status: TPatientStatus;
  recent_consultation_id?: string;
  recent_reference_id?: number;
  last_consultation_date_end?: Date;
  consultation_status: TPatientConsultationStatus;
  last_consultation_date: Date | null;
  consent_approved: boolean;
  same_delivery: boolean;
  same_billing: boolean;
  latest_prescription: IPrescriptionDetails;
  dva_no: string;
  ihi_no: string;
  ihi_record_status: string;
  ihi_status: string;
  ihi_verified: boolean;
  pinned_alert: string | null;
}

export interface IConsultationUpComingDetails {
  id: string;
  patient: IPatientDetails;
  start_at: Date;
  end_at: Date;
  consultation_type: IConsultationType;
  enquiry: string;
  notes: string;
  consultationStatus?: string;
}

export interface IProducts {
  id: string;
  type: string;
  brand: string;
  product_name: string;
  product_image: string;
  stock: number;
  format: string;
  schedule: string;
  price: string;
  is_active: boolean;
  is_formularly: boolean;
}

export interface IProductSelection {
  id?: string;
  product_id: string;
  no_of_units: string;
  repeats: string;
  dosage: string;
  frequency: string;
  route: string;
  product?: IProducts;

  // additional
  authority_no: string;
  brand_substitition_not_allowed: boolean;
  emergency_supply: boolean;
  annotations: boolean;
  unusual_dose: boolean;
  unusual_quantity: boolean;
  regulation_24: boolean;
}

export interface IProductCustom {
  id?: string;
  product_name: string;
  product_details: string;
  product_type: string;
  no_of_units: string;
  repeats: string;
  dosage: string;
  brand_name: string;
  sku: string;
  type: string;
  format: string;
  frequency: string;
  route: string;
}

export interface IPrescriptionDetails {
  id: string;
  patient_id: string;
  consultation_id: string;
  products: IProductSelection[];
  custom_products: IProductCustom[];
  consultation: IConsultationDetails;
  consultation_notes: string;
  treatment_notes: string;
  careteam_notes: string;
  followup_period: TFollowUpPeriod;
  followup_value: number;
  set_followup: boolean;
  with_escript: boolean;
  dispensing_interval: number;
  dispensing_notes: string;
  interval_reason: string;
  reference_id: number;
  post_prescriptions: {
    created_at: Date;
    notes: string;
  }[];
}

export interface IFileMutation {
  id?: number;
  filename: string;
  file_url: string;
  filetype?: TFileTypes;
}

export interface IEscripts {
  id: string;
  patient: IPatientDetails;
  doctor: IDoctorDetails;
  consultation_date: Date;
  created_at: Date;
  expiry: Date;
  product: IProducts;
  description: string;
  reference_id: number;
  remaining: number;
  total: number;
  status: TEscriptsStatus;
  tokens: IEscriptsToken[];
  erx_type: TErx;
}

export interface IEscriptsToken {
  id: string;
  doctor_name: string;
  source_ref: string;
  source_time: Date;
  expiry: Date;
  status: TEscriptTokenStatus;
  order_no: number | null;
  ready_at: Date | null;
  prescribed_at: Date;
  paid_at: Date | null;
}

export interface IFormOtpLogin {
  email: string;
  password: string;
  doctorId: string;
  otpHash: string;
  verificationCode: string;
  onClose?: () => void;
}

export interface ICurrentMedication {
  name: string;
  dose: string;
  frequency: string;
  reason: string;
}

export interface IMedicineDetails {
  type: string;
  frequency: string;
}

export interface IConsultationSummary {
  available: number;
  booked: number;
  percentage: number;
}

export interface IDispensingLimit {
  dispensing_limit: number;
  icon: TLimits;
  id: string;
  name: string;
  unit: string;
}

export interface IHistoryConsultationNotes {
  reference_id: number;
  start_at: Date;
  end_at: Date;
  prescription: IConsultationNotes;
}

export interface IConsultationNotes {
  careteam_notes: string;
  consultation_notes: string;
  treatment_notes: string;
}

export interface IDoctorDateSchedule {
  id?: string | null;
  start_at: Date;
  end_at: Date;
  recurring_id?: string | null;
}

export interface ICreateSchedule {
  days: string[];
  start_at: Date;
  end_at: Date;
  recurring_id?: string;
}

export interface IProductDetails {
  id: string;
  type: string;
  brand: string;
  product_name: string;
  product_image: string;
  description: string;
  stock: number;
  format: string;
  schedule: string;
  category: string;
  size: string;
  size_unit: string;
  weight: string;
  price: string;
  thc: string;
  cbd: string;
  is_thc: boolean;
  is_cbd: boolean;
  is_high_cbd: boolean;
  is_high_thc: boolean;
  is_active: boolean;
  updated_at: Date;
  product_type: IDispensingLimit;
  cultivar: string;
  strength: string;
  is_formularly: boolean;
  administration: string;
  estimate_dispatch: string;
  status: string;
}

export interface INotifications {
  content: string;
  id: string;
  is_read: boolean;
  link: string;
  title: string;
  created_at: string;
}

export interface IDoctorNotifications {
  notifications: INotifications[];
}

export interface IHandleNotification {
  id: string;
  is_read: boolean;
  link: string;
}

export interface IDispensingLimitHistory {
  clinical_notes: string;
  created_at: Date;
  doctor: IDoctorDetails;
  new_dispensing_limit: number;
  old_dispensing_limit: number;
  product_type: IDispensingLimit;
}

export interface IDispensingIntervalHistory {
  created_at: Date;
  dispensing_interval: number;
  interval_reason: string;
  updated_by: string;
}
export interface INoteDetails {
  patientName: string;
  consultationDate: Date;
  careTeamNotes: string;
  careTeamEditedBy: string;
  careTeamLastEdit: Date;
  consultationNotes: string;
  consultationId: string;
  notes: string;
  consultationName?: string;
  patientPicture?: string;
  consultationStatus?: string;
  // consultationType: IConsultationType[];
  // inquiry: string;
}

export interface IPatientDetailsLocationState {
  tab: 'consult' | 'document' | 'escript';
  id?: string;
}

export interface IMeetingArgs {
  status: string;
  consultationDetails: IConsultationDetails;
  handleEndSession: () => Promise<any>;
}
